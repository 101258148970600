import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PlanificationPage from '../planification/pages/planification';
import MainLayout from './MainLayout';
import LogoutPage from '../authentication/logout';
import LoginPage from '../authentication/login';
import DashboardPage from '../dashboard/pages/dashboard';
import RulesPage from '../rules/pages/rules';
import SettingsPage from '../settings/pages/settings';
import PropertyPage from '../property/pages/property';
import PropertyCategoryPage from '../property/pages/propertyCategoryPage';
import BlocksPage from '../blocks/pages/blocks';
import { AuthProvider } from './context/authcontext';
import { CommonProvider } from './context/commonContext';
import ProtectedRoute from '../components/protectedRoute';
import { PropertyProvider } from '../property/propertyContext';
import { CategoryProvider } from '../property/categoryContext';
import { RuleProvider } from '../rules/ruleContext';
import { PlanificationProvider } from '../planification/planificationContext';
import { SettingsProvider } from '../settings/settingsContext';
import { InventoryProvider } from '../inventory/inventoryContext';
import { BlocksProvider } from '../blocks/blocksContext';
import InventoryPage from '../inventory/pages/inventory';

const HouseKeepingRoutes = () => {
    return (
        <CommonProvider>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route element={<MainLayout />}>
                        <Route path="/planification" element={<ProtectedRoute ><PlanificationProvider><PlanificationPage /></PlanificationProvider></ProtectedRoute>} />
                        <Route path="/rules" element={<ProtectedRoute ><RuleProvider><RulesPage /></RuleProvider></ProtectedRoute>} />
                        <Route path="/property">
                            <Route index element={<ProtectedRoute ><PropertyProvider><PropertyPage /></PropertyProvider></ProtectedRoute>} />
                            <Route path="category/:categoryId" element={<ProtectedRoute ><CategoryProvider><PropertyCategoryPage /></CategoryProvider></ProtectedRoute>} />
                        </Route>

                        <Route path="/settings" element={<ProtectedRoute ><SettingsProvider><SettingsPage /></SettingsProvider></ProtectedRoute>} />
                        <Route path="/inventory" element={<ProtectedRoute ><InventoryProvider><InventoryPage /></InventoryProvider></ProtectedRoute>} />
                        <Route path="/blocks" element={<ProtectedRoute ><BlocksProvider><BlocksPage /></BlocksProvider></ProtectedRoute>} />
                    </Route>
                    <Route path="/logout" element={<LogoutPage />} />
                </Routes>
            </AuthProvider>
        </CommonProvider>
    );
};

export default HouseKeepingRoutes;
