import React from 'react';
import Swal from 'sweetalert2';
import { postWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';

class ModalSendBlock extends React.Component {
    static handleChangeStatus = async (block, onSuccess) => {
        console.log(block);
        const changeStatusApi = async (block) => {
            try {
                console.log(block);
                await postWithAuth(API_URLS.NEW_BLOCK_TO_RR_WITHOUT_CREATE_INTERNAL_BLOCK, block);
            } catch (error) {
                console.error('Error changing status:', error);
                throw error;
            }
        };

        const result = await Swal.fire({
            title: '¡Cuidado!',
            text: 'Enviar un bloqueo puede tener una repercusión en la propiedad',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Enviar bloqueo',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'btn btn-sm btn-primary',
                cancelButton: 'btn btn-sm btn-secondary',
            },
        });

        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Enviando...',
                    text: 'Por favor, espera mientras se envía el bloqueo',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    willOpen: () => {
                        Swal.showLoading();
                    },
                });

                await changeStatusApi(block);

                await Swal.fire({
                    icon: 'success',
                    title: 'Bloqueo enviado',
                    text: 'El bloqueo se ha enviado correctamente.',
                    confirmButtonText: 'Aceptar',
                    customClass: {
                        confirmButton: 'btn btn-sm btn-primary',
                    },
                });

                // En lugar de recargar la página, llamamos al callback `onSuccess`
                if (onSuccess) {
                    onSuccess();
                }

            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al enviar el bloqueo',
                    text: 'Hubo un problema al intentar enviar el bloqueo. Por favor, inténtalo de nuevo más tarde.',
                });
            }
        }
    };

    render() {
        return null;
    }
}

export default ModalSendBlock;
