import React, { createContext, useContext, useState } from 'react';

const BlockContext = createContext();

export const BlocksProvider = ({ children }) => {

    const [selectedRule, setSelectedRule] = useState(null);

    //Gestion del contenido de los Alert
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');


    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const closeAlertHeader = () => {
        setShowAlertHeader(false);
    };

    const [showNewBlockModal, setShowNewBlockModal] = useState(false);
        const openNewBlockModal = () => {
            setShowNewBlockModal(true);
        };
    
        const closeNewBlockModal = () => {
            setShowNewBlockModal(false);
        };
   

    const [selectedBlock, setSelectedBlock] = useState(null);
        const [showUpdateBlockModal, setShowUpdateBlockModal] = useState(false);
            const openUpdateBlockModal = (block_id) => {
                setSelectedBlock(block_id);
                setShowUpdateBlockModal(true);
            };
            const closeUpdateBlockModal = () => {
                setShowUpdateBlockModal(false);
            };
    

    const [apiSuccess, setApiSuccessSuccess] = useState(false);
    const changeApiSuccess = () => {
        setApiSuccessSuccess(!apiSuccess);
    };
    return (
        <BlockContext.Provider value={{
            apiSuccess,
            changeApiSuccess,
            alertType,
            setAlertType,
            setMessage,
            message,
            openAlertHeader,
            closeAlertHeader,
            showAlertHeader,
            selectedRule,
            setSelectedRule,
            openNewBlockModal,
            closeNewBlockModal,
            showNewBlockModal,
            openUpdateBlockModal,
            closeUpdateBlockModal,
            showUpdateBlockModal,
            selectedBlock
        }}>
            {children}
        </BlockContext.Provider>
    );
};

export const useBlocks = () => {
    return useContext(BlockContext);
};
