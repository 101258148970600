export const MainMenuList = {
    label: 'Main Menu',
    children: [
        {
            name: 'Planificación',
            icon: 'timeline',
            to: '/planification',
            //allowedRoles: [GENERAL_USER, ADMIN_USER],
            active: true
        },
        {
            name: 'Reglas',
            icon: 'pen-ruler',
            to: '/rules',
            //allowedRoles: [ADMIN_USER],
            active: true
        },
        {
            name: 'Configuración de propiedad',
            icon: 'door-open',
            to: '/property',
            //allowedRoles: [ADMIN_USER],
            active: true
        },
        {
            name: 'Inventario',
            icon: 'boxes-stacked',
            to: '/inventory',
            //allowedRoles: [ADMIN_USER],
            active: true
        },
        {
            name: 'Bloqueos',
            icon: 'ban',
            to: '/blocks',
            //allowedRoles: [ADMIN_USER],
            active: true
        },
        {
            name: 'Ajustes',
            icon: 'gear',
            to: '/settings',
            //allowedRoles: [ADMIN_USER],
            active: true
        }
    ]
};

export default [
    MainMenuList,
];