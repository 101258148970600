import React, { useContext, useRef, useEffect, useState } from 'react';
import { useBlocks } from '../../blocks/blocksContext';
import { API_URLS } from '../../api/apiConfig';
import AutoDismissAlert from '../../components/autoDismiss';
import { getWithAuth } from '../../api/api';
import ModalBlockCreate from '../../blocks/components/modalBlockCreate';
import ModalCancelBlock from '../../blocks/components/modalCancelBlock';
import ModalBlockUpdate from '../components/modalBlockUpdate';
import Form from "react-bootstrap/Form";
import ModalSendBlock from '../components/modalSendBlock';


const BlocksPage = () => {

    const blocksContext = useBlocks()

    const [blocksList, setListBlocks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState(""); // Estado para búsqueda
    const [startDate, setStartDate] = useState(""); // Estado para la fecha de inicio
    const [endDate, setEndDate] = useState("");
    const [page, setPage] = useState(1); // Estado para el número de página
    const [limit, setLimit] = useState(10); // Número de elementos por página
    const [total, setTotal] = useState(0); // Total de elementos
    const [functionalStatus, setFunctionalStatus] = useState("");


    const functionalStatusOptions = [
        { value: "", label: "Todos" },
        { value: "SentToRRAndConfirmed", label: "Enviado a RR y confirmado" },
        { value: "SentToRRAndWaitingAssignment", label: "Enviado a RR y esperando asignación" },
        { value: "NeedUpdateInRR", label: "Necesidad de actualización en RR" },
        { value: "UpdateSentToRR", label: "Actualización enviada a RR" },
        { value: "NeedToBeCancel", label: "Necesidad de cancelación" },
        { value: "Cancelled", label: "Cancelada" },
        { value: "BlockCreated", label: "Bloqueo Creado" },
    ];

    const getFunctionalStatusLabel = (status) => {
        const statusObj = functionalStatusOptions.find(option => option.value === status);
        return statusObj ? statusObj.label : "Sin estado"; // Si no encuentra coincidencia, muestra "Sin estado"
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const params = new URLSearchParams({
                page,
                limit
            });
    
            if (functionalStatus) { // Si hay un estado seleccionado, lo agregamos a la consulta
                params.append("functional_status", functionalStatus);
            }
    
            const response = await getWithAuth(`${API_URLS.GET_ALL_BLOCKS}?${params}`);
    
            setListBlocks(response.data.blocks);
            setTotal(response.data.total);
        } catch (error) {
            blocksContext.setMessage("Ha ocurrido un error conectando con el servidor");
            blocksContext.setAlertType("danger");
            blocksContext.openAlertHeader();
            console.error('Error getting blocks:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, limit, functionalStatus]);  // Ahora usa `functionalSta
    

    const getBadgeClass = (status) => {
        switch (status) {
            case "SentToRRAndConfirmed": return "badge-success";
            case "BlockCreated": return "badge-danger";
            case "SentToRRAndWaitingAssignment": return "badge-warning";
            case "NeedUpdateInRR": return "badge-primary";
            case "UpdateSentToRR": return "badge-info";
            case "BlockByLackOfReportInPEX": return "badge-dark";
            default: return "badge-secondary";
        }
    };

    const filteredBlocks = blocksList.filter((block) => {
        const roomName = block.rooms?.[0]?.name?.toLowerCase() || "";
        const roomNumber = block.rooms?.[0]?.number?.toString().toLowerCase() || "";
        const blockStart = block.start || ""; // Asumiendo que es un string tipo "YYYY-MM-DD"
        const blockEnd = block.end || "";
    
        const matchesSearch = roomName.includes(searchText.toLowerCase()) || roomNumber.includes(searchText.toLowerCase());
        
        const matchesStartDate = !startDate || blockStart >= startDate;
        const matchesEndDate = !endDate || blockEnd <= endDate;
    
        return matchesSearch && matchesStartDate && matchesEndDate;
      });

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">
                            <h1
                                className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center me-3 mb-3 my-0">
                                Bloqueos de propiedad</h1>
                        </div>
                    </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-fluid">
                    {blocksContext.showAlertHeader && (
                            <div className="">
                                <AutoDismissAlert message={blocksContext.message} alertType={blocksContext.alertType} onClose={blocksContext.closeAlertHeader} />
                            </div>
                        )}
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                                <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                                </div>
                            </div>
                        ) : (
                            <>
                        <div className="card">
                            <div className="card-header border-0 pt-6">
                            <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-gray-900">
                                        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                                            <div className="d-flex"> {/* Div para colocar los FormControl en una fila */}
                                                {/*
                                                <Form.Control
                                                className="m-3"
                                                placeholder="Fecha de inicio"
                                                name="start"
                                                type="date"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                />
                                               
                                                <Form.Control
                                                className="m-3"
                                                placeholder="Fecha de fin"
                                                name="end"
                                                type="date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                />
                                               <Form.Control
                                                className="m-3"
                                                placeholder="Búsqueda por nombre de habitación"
                                                type="text"
                                                value={searchText}
                                                onChange={(e) => setSearchText(e.target.value)} // Captura el valor ingresado
                                                />*/}
                                                <Form.Select 
                                                    className="m-3"
                                                    value={functionalStatus}
                                                    onChange={(e) => {
                                                        setFunctionalStatus(e.target.value);
                                                        setPage(1); // Reiniciar a la primera página al cambiar el filtro
                                                    }}
                                                >
                                                    {functionalStatusOptions.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Select 
                                                className="m-3" 
                                                value={limit} 
                                                onChange={(e) => setLimit(Number(e.target.value))}
                                            >
                                                <option value="5">5 por página</option>
                                                <option value="10">10 por página</option>
                                                <option value="20">20 por página</option>
                                            </Form.Select>
                        
                                            </div>
                                        </div>
                                    </span>
                                </h3>
                                
                                <div className="card-toolbar">
                    <a className="m-3 btn btn-sm btn-primary" onClick={() => blocksContext.openNewBlockModal()}>Nuevo Bloqueo</a>
                    </div>
                            </div>
                            
                            <div className="card-body py-4">
                                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                                    <thead>
                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">

                                            
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Inicio
                                            </th>
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Fin
                                            </th>
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Estado
                                            </th>
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Tipo
                                            </th>
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Habitación
                                            </th> 
                                            <th className="" rowSpan="1" colSpan="1" aria-label="Actions">Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 fw-semibold">
                                        {filteredBlocks.length > 0 ? (
                                            filteredBlocks.map((block, index) => (
                                            <tr key={index}>
                                                <td>{block.start}</td>
                                                <td>{block.end}</td>
                                                <td>
                                                    <span className={`badge ${getBadgeClass(block.functional_status)}`}>
                                                        {getFunctionalStatusLabel(block.functional_status)}
                                                    </span>
                                                </td>
                                                <td>
                                                    {block.block_type === "PEXWithReportSource"
                                                    ? "PEX con fuente de reporte"
                                                    : block.block_type === "BlankSpace"
                                                    ? "Espacio en blanco"
                                                    : block.block_type === "NonReportForRoom"
                                                    ? "No reportado por habitación"
                                                    : "Desconocido"}
                                                </td>

                                                <td>{block.rooms?.[0]?.name} {block.rooms?.[0]?.number}</td>
                                                <td>
                                                <TableActions block={block} fetchData={fetchData} /> {/* Pasamos fetchData aquí */}
                                                </td>
                                            </tr>
                                            ))
                                        ) : (
                                            <tr>
                                            <td colSpan="5" className="text-center">
                                                No se encontraron resultados
                                            </td>
                                            </tr>
                                        )}
                                        </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                </div>
                                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                    <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                                       
                                    </div>
                                </div>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                    <nav>
                                        <ul className="pagination">
                                            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => setPage(page - 1)}>Anterior</button>
                                            </li>
                                            <li className="page-item disabled">
                                                <span className="page-link">Página {page} de {Math.ceil(total / limit)}</span>
                                            </li>
                                            <li className={`page-item ${page * limit >= total ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => setPage(page + 1)}>Siguiente</button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        </div>
                     </>
                     )}
                    </div>
                </div>
            </div>
            {blocksContext.showNewBlockModal && 
            <ModalBlockCreate 
            onClose={() => blocksContext.closeNewBlockModal()} 
            />
            }

            {blocksContext.showUpdateBlockModal && 
            <ModalBlockUpdate
            onClose={() => blocksContext.closeUpdateBlockModal()} 
            block_id={blocksContext.selectedBlock}
            />
            }

        </>
    );

};

export default BlocksPage;


const TableActions = ({ block, fetchData }) => {

    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef(null);
    const anchorRef = useRef(null);
    const blocksContext = useBlocks()
    const [showAlert, setIshowAlert] = useState(false);
    const [message, setMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const toggleDropdown = () => {
        setShowMenu(!showMenu);
    };

    
    const handleCancelBlock = (block) => {
        ModalCancelBlock.handleChangeStatus(block);
    };

    const handleSendBlock = (block) => {
        ModalSendBlock.handleChangeStatus(block, fetchData);
    };

    useEffect(() => {
        
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    useEffect(() => {
        if (showMenu) {
            // Obtenemos las coordenadas del elemento anchor
            const anchorRect = anchorRef.current.getBoundingClientRect();
            const elementWidth = anchorRef.current.offsetWidth;
            // Calculamos la posición del menú
            const screenWidth = window.innerWidth;
            const menuX = anchorRect.left - screenWidth + elementWidth;
            const menuY = anchorRect.top + anchorRect.height + 10;
            setMenuPosition({ x: menuX, y: menuY });
        }
    }, [showMenu]);

    return (
        <>
            <a
                href="#"
                className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                onClick={toggleDropdown}
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                ref={anchorRef} // Referencia al elemento anchor
            >
                Acciones
                <i className="ki-outline ki-down text-color-azul-livensa fs-5 ms-1"></i>
            </a>
            <div
                ref={menuRef}
                style={{ transform: `translate3d(${menuPosition.x}px, ${menuPosition.y}px, 0)` }}
                className={`menu-actions-table menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 ${showMenu ? 'show' : ''}`}
                data-kt-menu="true"
                data-popper-placement="bottom-end"
            >
                <div className="menu-item px-3">
                <a onClick={() =>  blocksContext.openUpdateBlockModal(block.id)}  className="menu-link px-3" data-kt-ecommerce-product-filter="delete_row">
                        Actualizar
                    </a>
                </div>

                <div className="menu-item px-3">
                    <a onClick={() => handleCancelBlock(block.id)}  className="menu-link px-3" data-kt-ecommerce-product-filter="delete_row">
                        Eliminar
                    </a>
                </div>
                <div className="menu-item px-3">
                    <a onClick={() => handleSendBlock(block)}  className="menu-link px-3" data-kt-ecommerce-product-filter="delete_row">
                        Enviar a RR
                    </a>
                </div>
            </div>
        </>
    );
};
