import React from 'react';
import Swal from 'sweetalert2';
import { getWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';

class ModalCancelBlock extends React.Component {
    static handleChangeStatus = async (id) => {
        const changeStatusApi = async (id) => {
            try {
                await getWithAuth(API_URLS.CANCEL_BLOCK_TO_RR(id));
            } catch (error) {
                console.error('Error changing status:', error);
                throw error;
            }
        };

        const result = await Swal.fire({
            title: '¡Cuidado!',
            text: 'Eliminar bloqueo puede tener un repercusión en la propiedad',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Eliminar bloqueo',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'btn btn-sm btn-primary',
                cancelButton: 'btn btn-sm btn-secondary',
            },
        });

        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Eliminando...',
                    text: 'Por favor, espera mientras eliminamos el bloqueo',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    willOpen: () => {
                        Swal.showLoading(); // Mostrar spinner mientras la promesa se ejecuta
                    },
                });

                await changeStatusApi(id);

                await Swal.fire({
                    icon: 'success',
                    title: 'Bloqueo eliminado',
                    text: 'El bloqueo se ha eliminado correctamente.',
                    confirmButtonText: 'Aceptar',
                    customClass: {
                        confirmButton: 'btn btn-sm btn-primary',
                    },
                });

                // Recargar la página
                window.location.reload();
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al eliminar el bloqueo',
                    text: 'Hubo un problema al intentar eliminar el bloqueo. Por favor, inténtalo de nuevo más tarde.',
                });
            }
        }
    };

    render() {
        return null;
    }
}

export default ModalCancelBlock;
